import React, { useState } from "react";
import { Slider, Typography, Box } from "@mui/material";

interface RatingProportionSliderProps {
  initialValue: number;
  onChangeCommitted: (value: number) => void;
}

export const RatingProportionSlider: React.FC<RatingProportionSliderProps> = ({
  initialValue,
  onChangeCommitted,
}) => {
  const [localValue, setLocalValue] = useState(initialValue);

  return (
    <>
      <Slider
        value={localValue}
        onChange={(_, value) => {
          setLocalValue(value as number);
        }}
        onChangeCommitted={(_, value) => {
          onChangeCommitted(value as number);
        }}
        valueLabelDisplay="auto"
        step={5}
        min={0}
        max={100}
      />
      <Box display="flex" justifyContent="center">
        <Typography variant="caption">
          {localValue}% ratings, {100 - localValue}% completion
        </Typography>
      </Box>
    </>
  );
};
