export interface ItemOption {
  id: string;
  label: string;
  order: number;
  value: number;
}
export interface FormItem {
  id: string;
  category: string;
  itemType: ItemType;
  label: string;
  optional: boolean;
  minVariance?: number;
  options: ItemOption[];
  order: number;
  recipientType: ItemRecipientType;
  scale?: number;
  subjectType: ItemSubjectType;
  visibility?: ItemVisibility;
}

export enum ItemType {
  Instructions = "IN",
  Text = "TX",
  Choice = "CH",
  Points = "PT",
  Blank = "BL",
}

export const ItemTypeLabels = {
  IN: "Instructions",
  TX: "Text",
  CH: "Choice",
  PT: "Points",
  BL: "Blank",
};

export enum ItemRecipientType {
  TeamMembers = "TM",
  TeamLeaders = "TL",
}

export enum ItemSubjectType {
  PeerSelf = "PR",
  PeerOnly = "PO",
  Team = "TM",
  Course = "CS",
  General = "GL",
}

export enum ItemVisibility {
  Releasable = "RE",
  Private = "PR",
}

export interface Template {
  id: string;
  active: boolean;
  institutionCreated: boolean;
  isDirectShare: boolean;
  isGlobal: boolean;
  isShared: boolean;
  isSnapshot: boolean;
  isMine: boolean;
  instructions: string;
  items: FormItem[];
  modified: string;
  name: string;
  user: User;
}

export interface Answer {
  id: string;
  created?: string;
  itemId: string;
  modified: string;
  text?: string;
  value?: number | null;
}

export interface ResponseSubject {
  id: string;
  answers: Answer[];
  completed: boolean;
  subjectType: ResponseSubjectType;
  subjectUserId: string | null;
}

export interface ResponseSubjectWithAuthor {
  id: string;
  answers: Answer[];
  completed: boolean;
  subjectType: ResponseSubjectType;
  subjectUserId: string | null;
  authorDisplayName: string;
}

export enum ResponseSubjectType {
  Peer = "PR",
  Team = "TM",
  Course = "CS",
  General = "GL",
}

export interface Response {
  id: string;
  authorId: string;
  campaignId: string;
  form?: Template;
  groupId: string;
  responseSubjects: ResponseSubject[];
  submitted: boolean;
  tokenizedLink: string;
}

export interface MyResponse extends Partial<Response> {
  id: string;
  authorId: string;
  campaignId: string;
  campaignName: string;
  campaignDueDate: Date;
  campaignState: string;
  campaignReleased: Date;
  campaignResultsLink: string;
  groupId: string;
  submitted: boolean;
  tokenizedLink: string;
}

export interface Course {
  id: string;
  name: string;
  fullSyncCapable?: boolean;
  fullSyncReady?: boolean;
  fullSyncEnabled?: boolean;
  lmsType?: string;
  oauthUrl?: string;
}

export interface Section {
  id: string;
  name: string;
  course: Course;
  userIds: string[];
}

export enum CampaignState {
  Draft = "DR",
  Scheduled = "SC",
  Active = "AC",
  Closed = "CL",
}

export enum CampaignGradingMode {
  FixedAuto = "FA",
  Manual = "MN",
}

export interface CampaignActivity {
  id: string;
  label: string;
  created: string;
}

export interface Campaign {
  id: string;
  assignmentEnabled: boolean;
  assignmentGradingMode?: CampaignGradingMode;
  assignmentPoints?: string;
  assignmentRatingProportion?: number;
  autoCloseDate: string | null;
  autoReleaseDate: string | null;
  autoSendDate: string | null;
  course: Course;
  dueDate?: string;
  formId?: string;
  form?: Template;
  groupcategory?: GroupCategory;
  groups: Team[];
  name: string;
  released?: string;
  sectionId?: string;
  state: CampaignState;
  activity: CampaignActivity[];
  submittedResponses?: number;
  totalResponses?: number;
}

export interface Analysis {
  id?: string;
  question: FormItem;
  self: number | undefined;
  others: number | undefined;
  gap: number | undefined;
  given: number | undefined;
  user: User;
  team?: Team;
  label: string;
  outlier?: boolean;
  timestamp?: Date;
}

export interface SectionAnalysis {
  section: string;
  self: number | undefined;
  others: number | undefined;
  gap: number | undefined;
  given: number | undefined;
  user: User;
  label: string;
}

export interface StudentAverageAnalysis {
  self: number | undefined;
  others: number | undefined;
  gap: number | undefined;
  given: number | undefined;
  user: User;
  label: string;
  id: string;
  outlier: boolean;
}

export interface TeamAverageAnalysis {
  self: number | undefined;
  others: number | undefined;
  gap: number | undefined;
  given: number | undefined;
  team: Team;
  label: string;
  id: string;
  outlier: boolean;
}

export interface GenericAverageAnalysis {
  self: number | undefined;
  others: number | undefined;
  gap: number | undefined;
  given: number | undefined;
  label: string;
  id: string;
  outlier: boolean;
}

export interface CommentItem {
  id?: string;
  comment: string;
  respondent: User;
  recipient: User;
  team?: Team;
  timestamp?: Date;
}

export interface AggregateComments {
  id?: string;
  question: FormItem;
  comments: CommentItem[];
}

export interface PointRow {
  id?: string;
  question: FormItem;
  value: number;
  user: User;
  sender: User;
  team?: Team;
  label: string;
  timestamp?: Date;
}

export interface User {
  id: string;
  email: string;
  headshot: string;
  firstName: string;
  lastName: string;
  ssoId: string;
  isLearner: boolean;
}

export interface Team {
  id: string;
  leaderId?: string;
  userIds: string[];
  name: string;
  // sectionId: string;
}

export interface Data {
  responses: Response[];
  sections: Section[];
  teams: Team[];
  templates: Template[];
  users: User[];
  campaigns: Campaign[];
}

export interface OverallValues {
  self: number;
  given: number;
  received: number;
}

export interface Results {
  id: string;
  analyses: Analysis[];
  aggregates: AggregateComments[];
  overall: OverallValues;
  campaign: Campaign;
  form: Template;
  user: User;
}

export interface GroupCategory {
  id: string;
  name: string;
}

export const NewCategory = "New Category";
