import {
  Add,
  Business,
  Delete,
  Public,
  Security,
  SettingsBackupRestore,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  FormItem,
  ItemOption,
  ItemType,
  NewCategory,
  ItemType as QuestionTypeEnum,
  Template as TemplateType,
} from "../../types";
import { getNewQuestion } from "../../Data";
import { useTranslation } from "react-i18next";

import { TemplateForm } from "./Form";
import { getUnique } from "../../DataTransform";
import { getLabelWithRemainingChars } from "../../Helpers";
import { TypeMenu } from "../Question/Types";

function isNew(template: TemplateType) {
  if (template.items.length !== 1) {
    return false;
  }
  const question = template.items[0];
  const newQuestion = getNewQuestion(QuestionTypeEnum.Choice);
  return (
    question.label === newQuestion.label &&
    question.category === newQuestion.category
  );
}

export interface Props {
  template: TemplateType;
  onChange: (template: TemplateType) => void;
  onDelete?: (template: TemplateType) => void;
  onShare?: (template: TemplateType, share: boolean) => void;
}

export const TemplateEdit = ({
  template: safeTemplate,
  onChange,
  onDelete,
  onShare,
}: Props) => {
  const [phantomItems, setPhantomItems] = useState<FormItem[]>([]);
  const [nextCategory, setNextCategory] = useState<string>();
  const template = useMemo(
    () => ({
      ...safeTemplate,
      items: [...safeTemplate.items, ...phantomItems].sort(
        (a, b) => a.order - b.order,
      ),
    }),
    [safeTemplate, phantomItems],
  );
  const categories = useMemo(
    () => getUnique(template.items.map((x) => x.category)),
    [template.items],
  );

  const [editing, setEditing] = useState<string[]>(
    isNew(template) ? [template.items[0].id] : [],
  );

  const [addAnchorEl, setAddAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [t] = useTranslation();
  const addOpen = Boolean(addAnchorEl);

  function safeOnChange(template: TemplateType) {
    setPhantomItems(
      template.items.filter((q) => q.itemType === QuestionTypeEnum.Blank),
    );
    onChange({
      ...template,
      items: template.items.filter(
        (q) => q.itemType !== QuestionTypeEnum.Blank,
      ),
    });
  }

  function setTemplateTitle(label: string) {
    safeOnChange({ ...template, name: label });
  }

  function setTemplateInstructions(instructions: string) {
    safeOnChange({ ...template, instructions });
  }

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNextCategory(undefined);
    setAddAnchorEl(event.currentTarget);
  };

  const handleAddInCategoryClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    category: string,
  ) => {
    setNextCategory(category);
    setAddAnchorEl(event.currentTarget);
  };

  const closeAddMenu = () => {
    setAddAnchorEl(null);
  };

  function handleQuestionAdd(type: ItemType, options: ItemOption[]) {
    const clone = { ...template };
    const newQuestion = getNewQuestion(type);
    newQuestion.options = options;
    newQuestion.category =
      nextCategory ??
      (template.items[template.items.length - 1]?.category ||
        newQuestion.category);
    setNextCategory(undefined);
    newQuestion.order =
      (template.items[template.items.length - 1]?.order || 0) + 1;
    clone.items = [...clone.items, newQuestion];
    setEditing([...editing, newQuestion.id]);
    safeOnChange(clone);
    closeAddMenu();
  }

  function getNewCategoryName() {
    let category = NewCategory;
    let index = 1;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (categories.indexOf(category) === -1) {
        return category;
      }
      category = `${NewCategory} (${index++})`;
    }
  }

  function handleAddCategoryClick() {
    const clone = {
      ...template,
      items: [
        ...template.items,
        {
          ...getNewQuestion(QuestionTypeEnum.Blank, 1000),
          category: getNewCategoryName(),
        },
      ],
    };
    safeOnChange(clone);
  }

  function handleShareToggle(checked: boolean) {
    if (onShare) {
      onShare(template, checked);
    }
  }

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          action={
            <>
              <Button
                variant="contained"
                component={Link}
                size="small"
                endIcon={<Visibility />}
                to={`/preview-template/${template.id}`}
                sx={{ m: 1 }}
              >
                Preview
              </Button>
              {onDelete && (
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<Delete />}
                  sx={{ m: 1 }}
                  onClick={() => onDelete(template)}
                >
                  Delete
                </Button>
              )}
            </>
          }
          title={
            <TextField
              disabled={!template.isMine}
              variant="outlined"
              label={getLabelWithRemainingChars(
                t("templates.label"),
                template.name.length,
                255,
              )}
              value={template.name}
              fullWidth={true}
              size="small"
              onBlur={(e) => setTemplateTitle(e.target.value.trim())}
              onChange={(e) => setTemplateTitle(e.target.value)}
              inputProps={{ maxLength: 255 }}
            />
          }
        />
        <CardContent sx={{ pt: 0, pb: 0 }}>
          {!template.isMine && (
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Author:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {template.user ? template.user.email : "Feedback Loop team"}
                </Typography>
              </Grid>
            </Grid>
          )}
          {template.isMine && onShare && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                Share with other instructors within your Institution:
              </Typography>
              <Typography>Off</Typography>
              <Switch
                checked={template.isShared}
                onChange={(e) => handleShareToggle(e.target.checked)}
              />
              <Typography>On</Typography>
            </Stack>
          )}
        </CardContent>
        <CardActions>
          {template.isSnapshot ? (
            <>
              <SettingsBackupRestore sx={{ m: 1 }} />
              <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
                {t("templates.snapshot")}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
              {`Last Updated ${new Date(
                Date.parse(template.modified),
              ).toLocaleString()}`}
            </Typography>
          )}

          <Box sx={{ flex: 1 }}></Box>
          {template.isGlobal ? (
            <Tooltip
              title={"This template is available to all Feedback Loop users."}
            >
              <Public />
            </Tooltip>
          ) : template.isShared ? (
            <Tooltip
              title={
                "This template is available to all courses at your institution."
              }
            >
              <Business />
            </Tooltip>
          ) : (
            <Tooltip title={"This template is only visible to you."}>
              <Security />
            </Tooltip>
          )}
        </CardActions>
        <TypeMenu
          id="add-menu"
          disableEnforceFocus
          disableRestoreFocus
          anchorEl={addAnchorEl}
          open={addOpen}
          onClose={closeAddMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          onTypeSelect={handleQuestionAdd}
          template={template}
        />
      </Card>
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Survey Instructions (optional)" />
        <CardContent>
          <TextField
            disabled={!template.isMine}
            variant="outlined"
            label="Instructions"
            value={template.instructions}
            fullWidth
            multiline
            minRows={2}
            onBlur={(e) => setTemplateInstructions(e.target.value.trim())}
            onChange={(e) => setTemplateInstructions(e.target.value)}
          />
        </CardContent>
      </Card>
      <TemplateForm
        template={template}
        onAdd={handleAddInCategoryClick}
        onChange={safeOnChange}
        editing={editing}
        setEditing={setEditing}
      />
      <Box sx={{ textAlign: "center" }}>
        <Button
          disabled={!template.isMine}
          variant="contained"
          size="large"
          endIcon={<Add />}
          onClick={handleAddClick}
        >
          Add Question
        </Button>
        <Button
          disabled={!template.isMine}
          variant="contained"
          size="large"
          endIcon={<Add />}
          sx={{ ml: 2 }}
          onClick={handleAddCategoryClick}
        >
          Add Category
        </Button>
      </Box>
    </>
  );
};
